<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('DELIVERY_LOCATION.TITLE')}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title" v-if="!isRegistrationWizard">
        <h1>{{$t('DELIVERY_LOCATION.TITLE')}}</h1>
      </div>

      <div class="card biz-primary mt-3">
        <div class="card-header">
          {{$t('DELIVERY_LOCATION.HEADER')}}
          <p>{{$t('DELIVERY_LOCATION.HEADER_DESC')}}</p>
        </div>
        <div class="card-body">
          <p class="mb-4" style="color: red">{{$t('DELIVERY_LOCATION.MESSAGE_FOR_HOME_DELIVERLY')}}</p>
          <div class="bg-white p-4">
            <div v-on:click="selectCountry()" :class="{'country':true, 'is-selected': deliverableLocation.isSelected}">
              <span class="my-auto text-center" style="font-size: 1.5rem; font-weight:bold;">{{$t('DELIVERY_LOCATION.ALL_OF_THE_COUNTRY')}}</span>
            </div>
            <div class="mt-3" v-if="deliverableLocation.prefectures.length <= 0">
              <b-alert show variant="danger">{{$t('COMMON.NO_DATA')}}</b-alert>
            </div>
            <div v-bind:key="p.id" v-for="p in deliverableLocation.prefectures" :class="{'prefectures':true}">
              <div class="row">
                <div class="col-2">
                  <div v-on:click="selectPrefecture(p)" :class="{'prefecture-name':true, 'is-selected': p.isSelected}">
                    <span style="font-size: 1.2rem;font-weight:bold;">{{p.areaName}}</span>
                  </div>
                </div>
                <div class="col">
                  <div class="areas">
                    <div v-for="a in p.children" v-bind:key="a.id" v-on:click="selectArea(a, p)" :class="{'area':true, 'is-selected':a.isSelected, 'is-removed': a.isRemoved}">
                      {{a.areaName}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card biz-primary mt-3">
        <div class="card-header">
          {{$t('DELIVERY_LOCATION.EXCLUDED_AREA')}}
          <p>{{$t('DELIVERY_LOCATION.EXCLUDED_AREA_DESC')}}</p>
        </div>
        <div class="card-body">
          <b-textarea style="min-height: 150px;" v-model="excludedArea" :placeholder="$t('DELIVERY_LOCATION.EXCLUDED_AREA_PLACEHOLDER')"></b-textarea>
        </div>
      </div>
      <div class="text-center mt-5">
        <j-button variant="primary" size="lg" v-on:click="onSave">
          {{isRegistrationWizard?$t('COMMON.NEXT'):$t('COMMON.SAVE')}}
        </j-button>
        <div class="mt-4" v-if="!isRegistrationWizard">
          <j-button variant="light" to="/supplier/manage">
            <font-awesome-icon icon="arrow-left"></font-awesome-icon>
            {{$t('COMMON.GO_BACK')}}
          </j-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .country{
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #CCCCCC;
    background: #FFF;
    border-radius: 5px;
    cursor: pointer;
    transition: all 140ms ease-in;
    &:hover, &.is-selected{
      background: $biz-primary-light;
      border-color: $biz-primary;
    }
  }
  .prefectures{
    margin-top: 0.5rem;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    .prefecture-name {
      height: 70px;
      display:flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 1px solid #CCCCCC;
      border-right: 2px solid #CCCCCC;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: 100%;
      cursor: pointer;
      transition: all 140ms ease-in;
      &:hover, &.is-selected{
        background: $biz-primary-light;
        border-color: $biz-primary;
      }
    }
    .areas {
      clear: both;
      padding: .25rem .0rem;
      .area {
        float:left;
        width: 90px;
        height: 45px;
        margin: .25rem .15rem;
        border: 2px solid #CCCCCC;
        background: #FFF;
        border-radius: 5px;
        
        display:flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 140ms ease-in;
        &:hover,  &.is-selected{
          background: $biz-primary-light;
          border-color: $biz-primary;
        }
      }
    }
  }
</style>
<script>
  import Area from '@/model/Area';
  import CompanyInfo from '@/model/CompanyInfo';
  
  export default {
    props: ["isRegistrationWizard"],
    components: { },
    data: () => ({
      deliverableLocation:{
        name:"全国",
        prefectures:[],
      },
      excludedArea: ""
    }),
    mounted: function(){
      Promise.all([
        Area.getFullAreaTree(),
        CompanyInfo.getCompanyArea(this.$store.state.user.companyCode)
      ]).then(results => {
        let prefectures = results[0].data;
        prefectures.forEach(prefecture => {
          prefecture.children.forEach(area => {
            area.isSelected = results[1].data.areas.find(r => r.id === area.id) ? true : false;
            area.isLoaded = area.isSelected;
            if(area.isSelected) {
              prefecture.isSelected = true;
            }
          });
          if(prefecture.isSelected){
            this.deliverableLocation.isSelected = true;
          }
        });
        this.excludedArea = results[1].data.excludedArea;
        this.deliverableLocation.prefectures = prefectures;
      }).catch(reason => {
        this.$errorMessage(undefined, {reason});
      })
    },
    methods:{
      selectCountry(){
        this.deliverableLocation.isSelected = !this.deliverableLocation.isSelected;
        this.deliverableLocation.prefectures.forEach(item => {
          this.selectPrefecture(item, true, this.deliverableLocation.isSelected);
        });
      },
      selectPrefecture(prefecture, override, overrideValue){
        prefecture.isSelected = override ? overrideValue : !prefecture.isSelected;
        prefecture.children.forEach(item => {
          this.selectArea(item, prefecture, true, prefecture.isSelected);
        });
      },
      selectArea(area, prefecture, override, overrideValue){
        area.isSelected = override ? overrideValue : !area.isSelected;
        if(area.isSelected){
          prefecture.isSelected = true;
        }
        if(!prefecture.children.find(a=>a.isSelected)){
          prefecture.isSelected = false;
        }

        if(area.isLoaded){ // This data has been registered on the backend
          area.isRemoved = !area.isSelected;
        }else{
          area.isUpdated = true;
        }

        this.$forceUpdate();
      },
      onSave(){
        if(!this.deliverableLocation.prefectures.find(p => p.isSelected)){
          this.$warningMessage(this.$t('DELIVERY_LOCATION.MINIMUM_ONE_PREFECTURE'));
          return;
        }
        CompanyInfo.saveCompanyArea(
            this.$store.state.user.companyCode, 
            {
              areas: this.deliverableLocation.prefectures
                        .map(p => p.children.filter(a => a.isSelected || a.isRemoved))
                        .reduce((t, p) => t.concat(p)),
              excludedArea: this.excludedArea
            }
          )
          .then(() => {
            this.$successMessage(this.$t('DELIVERY_LOCATION.SUCCESS_MESSAGE'));
            this.deliverableLocation.prefectures.forEach(p => p.children.forEach(a => {a.isLoaded = a.isSelected; a.isUpdated = false; a.isRemoved = false;}));
            this.$forceUpdate();
            this.$emit('onSave', 'CategoryInfo');
            
          }).catch(reason => this.$errorMessage(undefined, {reason}));
        
      }
    }
  }
</script>